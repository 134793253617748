import React from 'react'
import { Link } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

import Layout from '../components/layout'
import Seo from '../components/seo'

import gcImg from '../images/me-music-colour-sqr.jpg'

const AboutSection = styled.section`
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 0 1rem;

  p {
    text-align: left;
  }

  a {
    color: #363636;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-color: #f4bc0b;
    transition: color 0.3s ease;
  }

  a:hover {
    color: #666;
  }
`

const About = ({ location }) => {
  const imgSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0, -150px, 0)',
    },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  })

  return (
    <Layout location={location}>
      <Seo title="About" />
      <AboutSection>
        <animated.div style={{ ...imgSpring }}>
          <img
            style={{
              display: 'block',
              maxWidth: '460px',
              width: '100%',
              height: 'auto',
              margin: '1rem auto 1.5rem',
              borderRadius: '50%',
            }}
            src={gcImg}
            alt="Gideon Caspi gigging"
            width="460"
            height="460"
          />
        </animated.div>
        <h1>I'm Gideon.</h1>
        <p>
          That's me up there, performing at an open mic night back when I
          travelled around the world in 2009 - those were the days... these days
          I've got less hair under my hat and don't do as much strumming as I'd
          like to - just as much as I can get away with!
        </p>
        <p>
          I've worked in various engineering and design roles through the years,
          often combining the two. The majority of my output is in code, but I
          like to keep things creative where I can, and specialise in the web's
          core languages of HTML, CSS and JavaScript in my day to day work. I've
          been working on the web long enough to reminisce about things like
          developing in Dreamweaver and the{' '}
          <a
            href="https://www.filamentgroup.com/lab/update-styling-the-button-element-with-css-sliding-doors-now-with-image-spr.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            sliding doors technique
          </a>{' '}
          - yikes!
        </p>
        <p>
          I specialise in the{' '}
          <a
            href="https://bradfrost.com/blog/post/frontend-design-react-and-a-bridge-over-the-great-divide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            front of the front-end
          </a>{' '}
          when it comes to engineering, but I love working on the rest of the
          stack where possible and am always fascinated to learn about creative
          ways to deliver effective sites and applications online.
        </p>
        <p>
          Want to know more or discuss anything?{' '}
          <Link to="/contact/">Why not get in touch&hellip;</Link>
        </p>
      </AboutSection>
    </Layout>
  )
}

export default About
